import React, { useContext, useEffect, Fragment } from 'react';
import './App.scss';
import { actions, db } from './store';
import SymptomsForm from "./components/Forms/SymptomsForm";
import CovidMap from './components/map/CovidMap';
import CountyStats from './components/CountyStats';
import firebase from './api/firebase';
import increase from './img/increase-symbol.svg'
import PatientInfo from './components/map/PatientInfo';
import useViewport from './hooks/useViewport';
//import DoTheFive from './img/DoTheFive_GIF_1200x1200.gif'
import Share from './components/Share'
import PlotGraph2 from './components/Illustrade/PlotGraph2'

const AppBody = () => {
  const { state, dispatch } = useContext(db);
  const { width } = useViewport();

  useEffect(() => {
    const PEvents = snapshot => {
      const patients = [];
      snapshot.forEach(function (data) {
        patients.push(data.val());
      });
      dispatch({ type: actions.SetPatients, payload: { Patients: patients, Icon: increase } });
    }
    firebase.ref("patients").on("value", PEvents);

  }, []);

  return (
    <Fragment>
      {(state.Zipcode !== '') && (<CovidMap />)}
      {(state.PinSelected && width < 667) ?
        (<PatientInfo info={state.SelectedPatientInfo} />)
        : null
      }
      {(state.Zipcode !== '') && (<SymptomsForm />)}
      {(state.Zipcode !== '') && (<CountyStats />)}
      {(state.Zipcode !== '') && (<PlotGraph2 />)}
      {(state.Zipcode !== '') && (<Share />)}
      {/* {(state.Zipcode !== '') && (<CasesStats />)} */}
      {/* {(<div className="DoTheFive"><img src={DoTheFive} alt="" /></div>)} */}
      {/* {(state.Zipcode !== '' && state.tempData.length > 0) && (<SimilarSymptoms />)} */}
      {/* {(state.Zipcode !== '' && state.statsTotal.length > 0) && (<NewSymptoms />)} */}
      {/* <CDC /> */}
    </Fragment>
  );
}
export default AppBody;