import React, { useReducer, useEffect } from 'react';
import './App.scss';
import { StoreReducer, data, db, actions } from './store';
import { GetZipCode } from './api/api';
import Header from './components/Header'
import useViewport from './hooks/useViewport';
import moment from 'moment'
import SimilarIcon from './img/sympom-symbol.svg'
import AppBody from './AppBody';
import Logo from './img/Logo.svg';
import Loading from './img/Loading.png';
import rawdata from './rawdata/healthWebsites.json'
import Credits from './components/Credits'


const selectedSmallDevice = 'mobile-Show-Selected-Stats';

let options = {
  enableHighAccuracy: true,
  timeout: 2050,
  maximumAge: 0
};

function App() {
  const [state, dispatch] = useReducer(StoreReducer, data)
  const { width } = useViewport();

  useEffect(() => {
    const geo = navigator.geolocation;

    const watcher = geo.watchPosition(r => {
      geo.clearWatch(watcher);

      const { latitude, longitude } = r.coords;
      GetZipCode(+latitude.toFixed(4), +longitude.toFixed(4)).then((result) => {

        setTimeout(() => {

          dispatch({
            type: actions.SetZipcode,
            Zipcode: result.data.address.postcode,
            showStateStats: false,
            lat: latitude,
            lng: longitude,
            zoom: 9,
            stateName: result.data.address.state,
            cityName: result.data.address.city,
            countyName: result.data.address.county,
            LoadingGeo: false
          });
        }, 2000);

      }, e => {
      }).catch(r => console.log(r));
    }, err => {
      if (err.code == err.PERMISSION_DENIED) {
        console.log("Your browser is blocking the geolocation. Please allow this site to find your zipcode.")
      }

      dispatch({
        type: actions.SetZipcode,
        Zipcode: "10001",
        lat: state.lat,
        lng: state.lng,
        zoom: 9,
        showStateStats: true,
        stateName: "New York",
        cityName: "New York City",
        countyName: "New York",
        LoadingGeo: false
      });
    }, options);
  }, []);

  useEffect(() => {
    if (state.isCredits) {
      dispatch({ type: actions.SetDevice, payload: { Device: "credits" } });
    }
    else if (width < 768) {
      dispatch({ type: actions.SetDevice, payload: { Device: "mobile" } });
    }
    else if (width > 767 && width < 1025) {
      dispatch({ type: actions.SetDevice, payload: { Device: "medium-web-667-1024" } });
    }
    else {
      dispatch({ type: actions.SetDevice, payload: { Device: "web" } });
    }
  }, [width, state.isCredits]);

  return (
    <db.Provider value={{ state, dispatch }}>
      {(state.LoadingGeo) && (<div className="Intro">
        <img src={Logo} alt="logo" />
        <img src={Loading} alt="Loading" />
      </div>)}
      {(!state.LoadingGeo) && (
        <div className="MainApp" data-theme={(state.PinSelected && width < 620) ? selectedSmallDevice : state.Device}>
          {!state.isCredits ? <><Header /><AppBody /></> : <><Header /><Credits /></>}
        </div>)
      }
    </db.Provider>
  );
}

export default App;
