import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
const CheckBox = props => {
    const { name, label, id, isActive, callBack, className } = props;
    return (
        <div className={`${className}`}>
            <div>
                <Checkbox name={name} id={`contact${id}`} checked={isActive}
                    onChange={e => { callBack(e) }} color="primary" value={isActive} />
            </div>
            <div>{label}</div>
        </div>
    );
}

export default CheckBox;