const Actions = {
    SetStats: "SetStats",
    SetCountryStats: "SetCountryStats",
    SetZipcode: "SetZipcode",
    SetPatients: "SetPatients",
    SetRecenter: "SetRecenter",
    SetCardStats: "SetCardStats",
    SetSelectedPin: "SetSelectedPin",
    SetDevice: "SetDevice",
    SetStateStats: "SetStateStats",
    FormSubmitted: "FormSubmitted",
    isCredits: "isCredits"
}


export default Actions