import React from 'react';
import { Marker } from 'react-map-gl';
import './pins.scss';

const Pins = (props) => {
  const { data, onClick } = props;
  const GetIcon = (Reported, patient) => {
    return (
      <>
        {(Reported === 1) && ((<div onClick={() => onClick(patient)} className="svgimgBoxRegular"><div className="svgimgRegular"></div></div>))}
        {(Reported > 1 && Reported < 5) && ((<div onClick={() => onClick(patient)} className="svgimgBoxlight"><div className="svgimglight"></div></div>))}
        {(Reported > 4) && (<div onClick={() => onClick(patient)} className="svgimgBoxDark"><div className="svgimgDark"></div></div>)}
      </>
    )
  }
  return data.map((patient, index) => {
    const size = 30;
    return (
      <Marker key={`marker-${index}`} longitude={patient.lng} latitude={patient.lat}>
        {GetIcon(patient.reported, patient)}
        <svg height={size} viewBox="0 0 24 24" style={{ cursor: 'pointer', fill: '#d00', stroke: 'none', transform: `translate(${-size / 2}px,${-size}px)` }} onClick={() => onClick(patient)} >
        </svg>
      </Marker>
    )
  });
}
export default Pins;