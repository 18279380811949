export default function ReportedStatsTotal(totals, action) {
    const statsTotal = [];

    if (totals.reported < 1) {
        totals.reported = 1;
    }
    //Cases Reported
    statsTotal.push({ icon: action.payload.Icon, stats: `${totals.reported}`, detail: ` with at least 1 symptom` });
    //All Symptoms
    statsTotal.push({ icon: action.payload.Icon, stats: `${totals.allSymptoms}`, detail: ` reported all symptoms` });
    statsTotal.push({ icon: action.payload.Icon, stats: `${totals.onlyCough}`, detail: ` only Cough` });
    statsTotal.push({ icon: action.payload.Icon, stats: `${totals.onlyFever}`, detail: ` only Fever` });
    statsTotal.push({ icon: action.payload.Icon, stats: `${totals.onlyBreath}`, detail: ` Shortness of Breath` });
    statsTotal.push({ icon: action.payload.Icon, stats: `${totals.coughAndFever}`, detail: ` Cough & Fever` });
    statsTotal.push({ icon: action.payload.Icon, stats: `${totals.coughAndBreath}`, detail: ` Cough & Shortness of Breath` });
    statsTotal.push({ icon: action.payload.Icon, stats: `${totals.feverAndBreath}`, detail: ` Fever & Shortness of Breath` });


    return statsTotal;
}
