import React, { useContext, useState } from 'react';
import { db, actions } from "../store"
import logo from '../img/Logo.svg'
import './Header.scss'
import useForm from '../hooks/useForm';
import { GetLocationFromZipCode, GetZipCode } from "../api/api"
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import SimilarIcon from '../img/sympom-symbol.svg';
import states from '../store/state-data.json';
import IconButton from '@material-ui/core/IconButton';
import { MdMyLocation } from 'react-icons/md'

const Header = () => {
    const { state, dispatch } = useContext(db);
    const [fields, setFields, reset, setZipcode] = useForm({ zip: state.Zipcode });


    const [stateAbbr, setstateAbbr] = useState(states.filter(s => s.name === state.stateName)[0].value);

    const onKeyDownHandler = e => {
        if (e.keyCode === 13) {
            send();
        }
    };
    const setStateLocation = (val) => {

        console.log(val);
        const newState = states.filter(s => s.value === val.target.value)[0];

        if (typeof newState !== 'undefined') {
            setstateAbbr(newState.value)
            GetZipCode(newState.lat, newState.lng).then((result) => {

                dispatch({
                    type: actions.SetZipcode,
                    Zipcode: result.data.address.postcode,
                    lat: newState.lat,
                    lng: newState.lng,
                    zoom: 6,
                    showStateStats: true,
                    stateName: result.data.address.state,
                    cityName: result.data.address.city,
                    countyName: result.data.address.county,
                    LoadingGeo: false
                });
                //dispatch({ type: actions.SetCardStats, payload: { SimilarIcon: SimilarIcon, moment: moment } })
                setZipcode(result.data.address.postcode);
            })
            setTimeout(function () { dispatch({ type: actions.SetRecenter, payload: { Recenter: true } }); }, 500);
        }
    }
    const send = () => {

        GetLocationFromZipCode(fields.zip, "us").then((result) => {

            const lat = +parseFloat(result.data[0].lat).toFixed(4);
            const lng = +parseFloat(result.data[0].lon).toFixed(4);

            GetZipCode(lat, lng).then((result) => {

                dispatch({
                    type: actions.SetZipcode,
                    Zipcode: fields.zip,
                    lat: lat,
                    lng: lng,
                    zoom: 9,
                    showStateStats: false,
                    stateName: result.data.address.state,
                    cityName: result.data.address.city,
                    countyName: result.data.address.county,
                    LoadingGeo: false
                });

                setZipcode(result.data.address.postcode);
            })
            setTimeout(function () { dispatch({ type: actions.SetRecenter, payload: { Recenter: true } }); }, 500);
        }).catch(r => console.log(r));
    }
    const handleFocus = (event) => event.target.select();
    const useStyles = makeStyles({
        root: { color: 'white' }
    });



    const GetMyLocation = () => {

        const geo = navigator.geolocation;
        if (!geo) {
            alert()
        }
        geo.getCurrentPosition(r => {
            //geo.watchPosition(r => {
            const { latitude, longitude } = r.coords;

            GetZipCode(+latitude.toFixed(4), +longitude.toFixed(4)).then((result) => {

                dispatch({
                    type: actions.SetZipcode,
                    Zipcode: result.data.address.postcode,
                    showStateStats: false,
                    lat: latitude,
                    lng: longitude,
                    zoom: 9,
                    stateName: result.data.address.state,
                    cityName: result.data.address.city,
                    countyName: result.data.address.county,
                    LoadingGeo: false
                });
                setZipcode(result.data.address.postcode);
                setTimeout(function () { dispatch({ type: actions.SetRecenter, payload: { Recenter: true } }); }, 500);
            }, e => {
            }).catch(r => console.log(r));
        }, err => {
            if (err.code == err.PERMISSION_DENIED) {
                alert("Your browser is blocking the geolocation. Please allow this site to find your zipcode.")
            }
        })

    }
    return (
        <div className="HeaderBox Header">
            <div className="LogoBox"><img src={logo} alt="Logo" /></div>
            {!state.isCredits && <><div className="ZipcodeBoxLabel">You are viewing:</div>

                <div className="ZipcodeBox">
                    <div className="HeaderSate">
                        <select value={stateAbbr} onChange={setStateLocation}>
                            {states.map(s => {
                                return (
                                    <option key={s.value} value={s.value}>{s.value}</option>
                                )
                            })}
                        </select>
                    </div>


                    <div>
                        <input id="zip" type="text" name="zip" value={fields.zip} onChange={setFields} onFocus={handleFocus} onKeyDown={onKeyDownHandler} pattern="[0-9]*" />
                        <IconButton aria-label="geMyLocation" onClick={GetMyLocation} style={{ color: "#3aa4ff", backgroundColor: '#ffffff17' }}>
                            <MdMyLocation />
                        </IconButton>
                    </div>
                </div></>}
        </div>
    )
}
export default Header;