const data = {
    isCredits: false,
    FormSubmitted: true,
    Patients: [],
    Loading: true,
    LoadingGeo: true,
    lastChecked: "",
    Zipcode: '',
    lat: 40.7128,
    lng: -74.0060,
    stateName: "",
    stateAbrev: "",
    cityName: "",
    countyName: "",
    zoom: 5,
    showStateStats: false,
    Recenter: false,
    covid19Stats: [],
    //countryStats: [],
    localStats: {
        confirmed: 0,
        death: 0,
        newSick: 0,
        newDeath: 0
    },
    statsTotal: [],
    allPatients: [],
    SelectedPatientInfo: null,
    PinSelected: false
}
export default data