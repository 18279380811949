import React, { useContext } from 'react'
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import { db, actions } from '../store/'

import './Credits.scss'

const CloseBtnstyle = makeStyles({

    root: {
        justifySelf: 'end',
        float: 'right',
        height: '43px',
        width: '43px',
        backgroundColor: '#f83d3d',
        fontSize: '18px'
    }

})

const Credits = () => {
    const { state, dispatch } = useContext(db);

    const closeBtnstyle = new CloseBtnstyle();
    return (
        <div className="Credits">
            <div id="teamtitle" >
                <b>Credits </b>
                <Fab color="primary" aria-label="add" className={closeBtnstyle.root} onClick={() => dispatch({ type: actions.isCredits })}>
                    X
                </Fab>
            </div>
            <div>For official information please visit <a href="https://www.cdc.gov/" target="_blank">The Center for Decease Control and Prevention</a>'s Website</div>
            <div><em>The COVID-19 data above(cofirmed and deceased cases) is provided by {state.localStats.source}. </em><em>The data was last updated on</em>{` ${new Date(state.localStats.lastUpdateAt).toLocaleDateString('en-US', { timeZone: 'UTC' })}`}</div>

        </div>
    );
}
export default Credits;