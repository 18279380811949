import React, { useState, useContext } from 'react';
import { Line } from 'rc-progress';
import './PatientInfo.scss'
import useViewport from '../../hooks/useViewport';
import { db, actions } from "../../store"

const PatientInfo = ({ info }) => {
  const { width } = useViewport();
  const { dispatch } = useContext(db);

  if (info.reported === 0) {
    info.reported = 1;
  }

  const [allS, setallS] = useState((info.haveAll / info.reported) * 100);
  const [onlyC, setonlyC] = useState((info.onlyCough / info.reported) * 100);
  const [onlyF, setonlyF] = useState((info.onlyFever / info.reported) * 100);
  const [onlyB, setonlyB] = useState((info.onlyBreath / info.reported) * 100);
  const [cAndF, setcAndF] = useState((info.coughAndFever / info.reported) * 100);
  const [fAndB, setfAndB] = useState((info.feverAndBreath / info.reported) * 100);
  const [cAndB, setcAndB] = useState((info.coughAndBreath / info.reported) * 100);

  React.useMemo(() => {

    setallS((info.haveAll / info.reported) * 100);
    setonlyC((info.onlyCough / info.reported) * 100);
    setonlyF((info.onlyFever / info.reported) * 100);
    setonlyB((info.onlyBreath / info.reported) * 100);
    setcAndF((info.coughAndFever / info.reported) * 100);
    setfAndB((info.feverAndBreath / info.reported) * 100);
    setcAndB((info.coughAndBreath / info.reported) * 100);

  }, [info]);

  const closePopup = () => {
    dispatch({ type: actions.SetSelectedPin, payload: { SelectedPatientInfo: null, PinSelected: false } });
  }

  return (
    <div className="SelectedPin">
      {width < 667 && (<button class="mapboxgl-popup-close-button" onClick={closePopup} type="button">X</button>)}
      <div className="SelectedPinInner">
        <div>{info.reported} people reported symptoms</div>
        <div className="DetailGraphLineParent">
          {allS > 0 && (
            <div className="DetailGraphLineBox">
              <div>All Symptoms ({info.haveAll})</div>
              <div><Line percent={`${allS}`} /></div>
            </div>)}
          {cAndF > 0 && (
            <div className="DetailGraphLineBox">
              <div>Cough + Fever ({info.coughAndFever}):</div>
              <div><Line percent={`${cAndF}`} /></div>
            </div>)}
          {cAndB > 0 && (
            <div className="DetailGraphLineBox">
              <div>Cough+Trouble Breathing({info.coughAndBreath}):</div>
              <div><Line percent={`${cAndB}`} /></div>
            </div>)}
          {fAndB > 0 && (
            <div className="DetailGraphLineBox">
              <div>Fever+Trouble Breathing({info.feverAndBreath}):</div>
              <div><Line percent={`${fAndB}`} /></div>
            </div>)}
          {onlyC > 0 && (
            <div className="DetailGraphLineBox">
              <div>Cough ({info.onlyCough}):</div>
              <div><Line percent={`${onlyC}`} /></div>
            </div>)}
          {onlyF > 0 && (
            <div className="DetailGraphLineBox">
              <div>Fever({info.onlyFever}):</div>
              <div><Line percent={`${onlyF}`} /></div>
            </div>)}
          {onlyB > 0 && (
            <div className="DetailGraphLineBox">
              <div>Shortness of Breath ({info.onlyBreath}):</div>
              <div><Line percent={`${onlyB}`} /></div>
            </div>)}
        </div>
      </div>
    </div>
  );

}

export default PatientInfo;