
export default function SetUpPatientsAndTotals(action) {
    let PatientsByZipCode = [];
    let totals = {
        allSymptoms: 0,
        onlyCough: 0,
        onlyFever: 0,
        onlyBreath: 0,
        coughAndFever: 0,
        feverAndBreath: 0,
        coughAndBreath: 0,
        reported: 0
    };

    action.payload.Patients.forEach((p) => {

        let report = {
                haveAll: (p.cough && p.fever && p.shortnessBreath) ? 1 : 0,
                onlyCough: (p.cough && !(p.fever || p.shortnessBreath)) ? 1 : 0,
                onlyFever: (p.fever && !(p.cough || p.shortnessBreath)) ? 1 : 0,
                onlyBreath: (!(p.fever || p.cough) && p.shortnessBreath) ? 1 : 0,
                coughAndFever: (p.cough && p.fever && !p.shortnessBreath) ? 1 : 0,
                feverAndBreath: (!p.cough && p.fever && p.shortnessBreath) ? 1 : 0,
                coughAndBreath: (p.cough && !p.fever && p.shortnessBreath) ? 1 : 0,
                reported: 1
        }
        let nIndex = PatientsByZipCode.findIndex(z => z.zipcode === p.Zipcode);
        
        if (nIndex === -1) {
            
            let newPatient = { 
                zipcode: p.Zipcode,
                lat: p.lat,
                lng: p.lng,
                county: p.countyName
            }

            Object.keys(report).forEach( k => { 
                newPatient[k] = report[k];
            });

            PatientsByZipCode.push(newPatient);
        }
        else {
            Object.keys(report).forEach( k => { 
                PatientsByZipCode[nIndex][k] += report[k];
            });

        }
        Object.keys(report).forEach( k => { 
            totals[k] += report[k];
        });
    });
    return { totals, PatientsByZipCode };
}

