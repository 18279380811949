import React, { useEffect, useContext, useState } from 'react';
import './PlotGraph.scss'

import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import useVireport from '../../hooks/useViewport'
import { db, actions } from "../../store"


const PlotGraph2 = () => {
    const { state } = useContext(db);
    const [reportedStats, setreportedStats] = useState([]);

    //Calculate by 
    React.useMemo(() => {
        //Set weeks to show

        let isState = state.showStateStats;
        let currentDay = Date.now();
        let day = new Date(currentDay).getUTCDay();
        let firstSunday = new Date(currentDay).getDate() - day + (day == 0 ? -7 : 0) - 42;
        let dates = [];

        for (let i = 0; i < 7; i++) {
            let tempDate = new Date(currentDay).setDate(firstSunday);
            dates.push(new Date(tempDate).toLocaleDateString('en-US', { timeZone: 'UTC' }));
            firstSunday += 7;
        }
        //dates.push(new Date(currentDay).toLocaleDateString('en-US', { timeZone: 'UTC' }));

        let stateName = state.stateName;
        let countyName = state.countyName;
        let cityName = state.cityName;

        if (cityName === "New York") {
            cityName = "New York City";
        }
        let cityCounties = [{ county: "Queens County", city: "New York City" },
        { county: "Bronx County", city: "New York City" },
        { county: "Kings County", city: "New York City" },
        { county: "New York County", city: "New York City" },
        { county: "New York City", city: "New York City" },
        ];

        let nIndex = cityCounties.findIndex(c => c.county === countyName);


        let localPatients = state.allPatients.filter(p => p.countyName === countyName);
        if (nIndex !== -1) { //We're in NY
            localPatients = state.allPatients.filter(p => (cityCounties.some(c => c.county === p.countyName)));
        }

        if (isState) {
            localPatients = state.allPatients.filter(p => p.stateName === stateName);
        }

        let patientStats = [];
        dates.forEach(d => {
            patientStats.push({
                date: d,
                reported: 0,
                twoormore: 0,
                Total_Confirmed: 0,
                New_Cases: 0
            });
        });
        localPatients.forEach(p => {
            let tempDates = dates.filter(d => new Date(d) >= p.dateentered);

            let twoOrmore = ((p.cough ? 1 : 0) + (p.fever ? 1 : 0) + (p.shortnessBreath ? 1 : 0)) > 1 ? 1 : 0;
            if (tempDates.length > 0) {
                tempDates.forEach(td => {
                    let idx = patientStats.findIndex(s => s.date === td);
                    if (idx !== -1) {
                        patientStats[idx].reported++;
                        patientStats[idx].twoormore += twoOrmore;
                    } else {
                        patientStats.push({
                            date: td,
                            reported: 1,
                            twoormore: twoOrmore,
                            Total_Confirmed: 0,
                            New_Cases: 0
                        })
                    }
                });

            }
        });
        //NY Times Stats
        let stateData = state.covid19Stats.filter(item => (`${item.county} County` === countyName));
        if (stateData.length === 0) {
            stateData = state.covid19Stats.filter(item => (item.county === cityName || item.county === `${cityName} City`));
        }
        if (stateData.length === 0 || isState) {
            stateData = state.covid19Stats.filter(item => (item.state === stateName));
        }

        if (typeof stateData !== "undefined") {
            stateData.forEach((item) => {
                if (typeof item.dates !== "undefined") {
                    item.dates.forEach(d => {
                        let tempDate = new Date(`${d.date}T00:00:00`).toLocaleDateString('en-US', { timeZone: 'UTC' });
                        let idx = patientStats.findIndex(s => s.date === tempDate);
                        if (idx !== -1) {
                            patientStats[idx].Total_Confirmed += d.confirmed;
                        }
                    });
                }
            });
        }
        //let tIdx = patientStats.findIndex(s => s.date === new Date(currentDay).toLocaleDateString('en-US', { timeZone: 'UTC' }));
        //patientStats[tIdx].Total_Confirmed = state.localStats.confirmed;

        patientStats.forEach( (item, key) => { 
            if (key > 0) { 
                patientStats[key].New_Cases = patientStats[key].Total_Confirmed - patientStats[(key-1)].Total_Confirmed;
            }
        });

        setreportedStats(patientStats);
    }, [state.Patients, state.countyName, state.localStats]);
    const data = React.useMemo(
        () => reportedStats.map(s => ({ name: s.date, reported: s.reported, multisymptoms: s.twoormore, Total_Confirmed: s.Total_Confirmed, New_Cases: s.New_Cases }))
        ,
        [reportedStats]
    )
    return (
        <div className="PlotGraph" >
            <ResponsiveContainer>
                <LineChart data={data} >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="reported" stroke="#8884d8" />
                    <Line type="monotone" dataKey="multisymptoms" stroke="#82ca9d" />
                    <Line type="monotone" dataKey="New_Cases" stroke="#FF4500" />
                    <Line type="monotone" dataKey="Total_Confirmed" stroke="#b10dc9" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export default PlotGraph2;