import React, { useContext } from "react";
import ShareButton from "react-social-share-buttons";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import { db, actions } from "../store/";

import "./Share.scss";

import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

const CloseBtnstyle = makeStyles({
  root: {
    justifySelf: "end",

    height: "30px",
    width: "43px",
    backgroundColor: "#969696",
    fontSize: "12px",
    width: "159px",
  },
});

const Share = () => {
  const { state, dispatch } = useContext(db);
  const closeBtnstyle = new CloseBtnstyle();
  return (
    <div className="Share">
      <div className="teamcredits">
        <div>
          <b>
            <em>Engineers</em>{" "}
          </b>

          <a
            href="https://www.linkedin.com/in/dioscar-rodriguez-16166a47"
            target="_blank"
          >
            Dioscar Rodriguez
          </a>
          <span>, and </span>
          <a href="https://www.linkedin.com/in/dev-georgecd/" target="_blank">
            George Cruz Deschamps
          </a>
        </div>
        <div>
          <b>
            <em>Front-End Developer and Design </em>
          </b>

          <a href="https://dionelrodriguez.com/" target="_blank">
            Dionel Rodriguez
          </a>
        </div>
      </div>

      <div className="shareme">
        <div className="sharetitle">Share us</div>
        <ShareButton
          compact
          socialMedia={"facebook"}
          url={"https://covidtrack.app/"}
          media={"https://covidtrack.app/static/media/Logo.1bb79552.svg"}
          text="Track Covid-19"
        />
        <ShareButton
          compact
          socialMedia={"twitter"}
          url={"https://covidtrack.app/"}
          media={"https://covidtrack.app/static/media/Logo.1bb79552.svg"}
          text="Track Covid-19"
        />
        <ShareButton
          compact
          socialMedia={"pinterest"}
          url={"https://covidtrack.app/"}
          media={"https://covidtrack.app/static/media/Logo.1bb79552.svg"}
          text="Track Covid-19"
        />
        <div>
          <WhatsappShareButton
            size={32}
            round={false}
            url="https://covidtrack.app/"
          />
        </div>
      </div>
      <div className="Sources">
        <Fab
          variant="extended"
          color="primary"
          aria-label="add"
          className={closeBtnstyle.root}
          onClick={() => {
            dispatch({ type: actions.isCredits });
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          Sources...
        </Fab>
      </div>
    </div>
  );
};
export default Share;
