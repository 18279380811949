import React,{useState,useEffect} from 'react'
import thankyouLogo from '../img/Thumbs-up.png'
import Read10things from './Read10things';
import useInterval from '../hooks/useInterval'

const ThankYou = ()=>{
    const [interval, setInterval] = useState(true)
    let [count, setCount] = useState(0);

    useInterval(() => {
        setInterval(prv=>!prv);
      setCount(count + 1);
    }, 2000);

    return(<div className="ThankYou" >
                <div style={{display:'grid', justifyContent:'end'}} ><img src={thankyouLogo} alt=""/></div>

                <div className="Thankyouwords">

                    {(interval)&&(<><div>Thank You</div>
                    <div>For helping your</div>
                    <div>Community</div></>)}

                    {(!interval)&&(<><div>Sorry you’re feeling ill.</div>
                    <div>Stay home and </div>
                    <div>Take care of yourself</div></>)}
                </div>

                <Read10things />
            </div>)
}
export default ThankYou;