import React, { useContext, useEffect, useState } from 'react'
import './CountyStats.scss'
import { db, actions } from '../store';
import firebase from "../api/firebase"

const CountyStats = () => {
  const { state, dispatch } = useContext(db);
  const [CurrentStats, SetCurrentStats] = useState([]);
  const [PreviousStats, SetPreviousStats] = useState([]);

  //Get NY Times Stats
  useEffect(() => {
    firebase.ref("CovidStats/stats").orderByChild("state").equalTo(state.stateName).once("value", snapshot => {
      const stats = [];
      snapshot.forEach(function (data) {
        stats.push(data.val());
      });

      //SetTimesStats(stats);
      dispatch({
        type: actions.SetStats,
        payload: {
          lastChecked: Date.now(),
          covid19Stats: stats,
          Loading: false
        }
      });
    });
  }, [state.stateName, state.Zipcode]);

  //Get Current API Stats
  useEffect(() => {
    firebase.ref("CountryStats/CurrentStats/stats")
      .orderByChild("province").equalTo(state.stateName)
      .once("value", (snapshot) => {
        const stats = [];
        snapshot.forEach(function (data) {
          stats.push(data.val());
        });

        SetCurrentStats(stats);
      });
  }, [state.covid19Stats]);

  //Get Previous API Stats
  useEffect(() => {
    firebase.ref("CountryStats/PreviousStats/stats")
      .orderByChild("province").equalTo(state.stateName)
      .once("value", (snapshot) => {
        const stats = [];

        snapshot.forEach(function (data) {
          stats.push(data.val());
        });

        SetPreviousStats(stats);
      });
  }, [CurrentStats]);

  //Calculate Stats
  useEffect(() => {

    let isState = state.showStateStats;
    let retObj = { location: '', confirmed: 0, death: 0, newSick: 0, newDeath: 0, lastUpdateAt: '', source: "The New York Times" };

    //NY Times Stats
    let stateName = state.stateName;
    let countyName = state.countyName;
    let cityName = state.cityName;

    if (cityName === "New York") {
      cityName = "New York City";
    }
    let cityCounties = [{ county: "Queens County", city: "New York City" },
    { county: "Bronx County", city: "New York City" },
    { county: "Kings County", city: "New York City" },
    ];

    let nIndex = cityCounties.findIndex(c => c.county === countyName);
    if (nIndex !== -1) {
      countyName = cityCounties[nIndex].city;
    }

    let stateData = state.covid19Stats.filter(item => (isState ? (item.state === stateName) : (`${item.county} County` === countyName)));
    if (!isState) { //Only one record per county
      retObj.location = `${countyName}`;
      if (!isState && stateData.length === 0) {
        stateData = state.covid19Stats.filter(item => (item.county === cityName || item.county === `${cityName} City`));
        if (stateData.length > 0) {
          retObj.location = countyName;
        }
      }
    } else {
      retObj.location = stateName;
    }
    if (stateData.length === 0) {
      stateData = state.covid19Stats.filter(item => (item.state === stateName));
      retObj.location = stateName;
    }

    stateData.forEach((item) => {
      retObj.confirmed += item.confirmed;
      retObj.death += item.death;
      retObj.newSick += item.newSick;
      retObj.newDeath += item.newDeath;
      retObj.lastUpdateAt = `${item.lastDate}T00:00:00`;
    }, 0);

    //CovidAPI Stats
    let cRetObj = { location: '', confirmed: 0, death: 0, newSick: 0, newDeath: 0, lastUpdateAt: '', source: "RapidAPI" };
    let cStateData = CurrentStats.filter(item => (isState ? (item.province === stateName) : (item.city === cityName)));
    let cPrevData = PreviousStats.filter(item => (isState ? (item.province === stateName) : (item.city === cityName)));

    cRetObj.location = isState ? stateName : cityName;
    if (cStateData.length === 0) {
      cStateData = CurrentStats.filter(item => (item.province === stateName));
      //cPrevData = PreviousStats.filter(item => (item.province === stateName ));
      cRetObj.location = stateName;
    } else if (!isState) {
      cRetObj.location = cStateData[0].city;
    }

    cStateData.forEach((item, key) => {
      let nOld = cPrevData.find((pd) => (pd.city === item.city && pd.county === item.county));
      if (nOld === null || typeof nOld === "undefined") {
        nOld = { deaths: 0, confirmed: 0 };
      }
      cRetObj.confirmed += item.confirmed;
      cRetObj.death += item.deaths;
      cRetObj.newSick = item.confirmed - nOld.confirmed;
      cRetObj.newDeath += item.deaths - nOld.deaths;
      cRetObj.lastUpdateAt = item.lastUpdate;
    }, 0);

    if (cRetObj.location === retObj.location) {
      if (cRetObj.lastUpdateAt > retObj.lastUpdateAt) {
        retObj = cRetObj;
      }
    }

    //setlocalStats(retObj);
    dispatch({ type: actions.SetCardStats, localStats: retObj });

  }, [PreviousStats]);

  return (
    <div className="CountyStats">
      {(typeof state.localStats.lastUpdateAt !== "undefined") ? (<>
        <div className="">{state.localStats.location}</div>
        <div className="">Confirmed</div>
        <div className="">{state.localStats.confirmed}</div>
        <div className="">{state.localStats.newSick > 0 ? state.localStats.newSick : 0}</div>

        <div className="">Deceased</div>
        <div className="">{state.localStats.death}</div>
        <div className="">{state.localStats.newSick > 0 ? state.localStats.newDeath : 0}</div>

      </>) : (<>Loading...</>)
      }
    </div>
  )
}
export default CountyStats
