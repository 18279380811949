
import { OfficialStatsFiltering, ReportedStatsTotal, SetUpPatientsAndTotals } from './utils';

const StoreReducer = (state, action) => {
    switch (action.type) {
        case "isCredits":
            return { ...state, isCredits: !state.isCredits }
        case "FormSubmitted":
            return { ...state, FormSubmitted: false }
        case "SetDevice":
            return { ...state, Device: action.payload.Device }
        case "SetSelectedPin":
            return { ...state, SelectedPatientInfo: action.payload.SelectedPatientInfo, PinSelected: action.payload.PinSelected }
        case "SetCardStats":
            //const tempData = OfficialStatsFiltering(state);

            return { ...state, localStats: action.localStats }
        case "SetPatients":
            let { totals, PatientsByZipCode } = SetUpPatientsAndTotals(action);
            const statsTotal = ReportedStatsTotal(totals, action);
            return { ...state, Patients: PatientsByZipCode, statsTotal: statsTotal, allPatients: action.payload.Patients }
        case "SetRecenter":
            return { ...state, Recenter: action.payload.Recenter }
        case "SetZipcode":

            return {
                ...state,
                Zipcode: action.Zipcode,
                lat: action.lat,
                lng: action.lng,
                zoom: action.zoom,
                showStateStats: action.showStateStats,
                stateName: action.stateName,
                cityName: action.cityName,
                countyName: action.countyName,
                LoadingGeo: action.LoadingGeo
            }
        case "SetStateStats":
            return { ...state, showStateStats: action.showStateStats }
        case "SetStats":
            return { ...state, lastChecked: action.payload.lastChecked, covid19Stats: action.payload.covid19Stats, Loading: action.payload.Loading }
        case "SetCountryStats":
            return { ...state, lastChecked: action.payload.lastChecked, countryStats: action.payload.countryStats, Loading: action.payload.Loading }
        default:
            return { ...state }
    }
}
export default StoreReducer;
