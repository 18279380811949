import firebase from 'firebase';

  var firebaseConfig = {
    apiKey: "AIzaSyAfsFhqtocvUChFoEeMqgYzIMFVTvLYcR8",
    authDomain: "covid-19-1dbf8.firebaseapp.com",
    databaseURL: "https://covid-19-1dbf8.firebaseio.com",
    projectId: "covid-19-1dbf8",
    storageBucket: "covid-19-1dbf8.appspot.com",
    messagingSenderId: "205974079232",
    appId: "1:205974079232:web:6ecaaf218f3cf4ce8615e5",
    measurementId: "G-BY51009HLN"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  const data = firebase.database();

export default data;
