import React, { useState, useContext, useEffect, useCallback } from 'react';
import { db, actions } from "../../store"
import MapGL, { Popup, NavigationControl, ScaleControl } from 'react-map-gl';
import PatientInfo from "./PatientInfo";
import Pins from "./pins";
import useViewport from '../../hooks/useViewport';

const CovidMap = () => {
  const { state, dispatch } = useContext(db);
  //const [popupInfo, setpopupInfo] = useState(null);
  const { width } = useViewport();

  const scaleControlStyle = {
    position: 'absolute',
    bottom: 36,
    left: 0,
    padding: '10px'
  };

  const navStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '10px'
  };

  const [viewport, setViewport] = useState({
    latitude: state.lat,
    longitude: state.lng,
    zoom: state.zoom,
    bearing: 0,
    pitch: 0
  });

  useEffect(() => {
    if (state.Recenter) {
      viewport.latitude = state.lat;
      viewport.longitude = state.lng;
      viewport.zoom = state.zoom;
      setViewport(viewport);
      dispatch({ type: actions.SetRecenter, payload: { Recenter: false } });
    }
  }, [state.Recenter, state.lat, state.lng, viewport, dispatch, state.zoom])

  const changeViewport = (viewport) => {
    const { zoom } = viewport;

    if (zoom < 9 && !state.showStateStats) {

      //dispatch({type: actions.setStateStats, showStateStats: true });
      //setTimeout( () => { 
      //dispatch({ type: actions.SetCardStats, payload: true})
      //} , 2000 );
    }
    setViewport(viewport);
  }

  const onClickMarker = useCallback(patient => {
    //setpopupInfo(patient);
    dispatch({ type: actions.SetSelectedPin, payload: { SelectedPatientInfo: patient, PinSelected: true } });
    changeViewport({
      ...viewport,
      longitude: patient.lng,
      latitude: patient.lat,
      transitionDuration: 500
    });

    // dispatch({
    //   type: actions.SetZipcode,
    //   Zipcode: patient.Zipcode,
    //   lat: patient.lat,
    //   lng: patient.lng,
    //   zoom: 9,
    //   showStateStats: false,
    //   stateName: patient.stateName,
    //   cityName: patient.cityName,
    //   countyName: patient.countyName,
    //   LoadingGeo: false
    // });
  }, [dispatch]);

  //Todo: This function should receive a prop
  const renderPopup = () => {
    return (
      state.SelectedPatientInfo && (state.Device === "web" || (width > 668 && width < 1025)) && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={state.SelectedPatientInfo.lng}
          latitude={state.SelectedPatientInfo.lat}
          closeOnClick={true}
          onClose={() => {
            //setpopupInfo(null);
            dispatch({ type: actions.SetSelectedPin, payload: { SelectedPatientInfo: null, PinSelected: false } });
          }}>
          <PatientInfo info={state.SelectedPatientInfo} />
        </Popup>
      )
    );
  }
  return (
    <div id="map" className="CovidMap">
      {state.Zipcode !== "1" && (<MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/dark-v9"
        onViewportChange={changeViewport}
        mapboxApiAccessToken={process.env.REACT_APP_TOKEN}
      >
        <Pins data={state.Patients} onClick={onClickMarker} />
        {renderPopup()}
        <div style={navStyle}>
          <NavigationControl />
        </div>
        <div style={scaleControlStyle}>
          <ScaleControl unit="imperial" />
        </div>
      </MapGL>)}
    </div>
  );
}

export default CovidMap;
