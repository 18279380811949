import React from 'react'

const Read10things = ()=>
{

    return(<blockquote cite="https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/caring-for-yourself-at-home.html" className="Read10things">
        <span>Read 10 ways to manage your health at home. </span>
        <a href="https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/caring-for-yourself-at-home.html">
        Follow these steps
        </a> <span>now to help protect others from getting sick</span>
    </blockquote>);
}
export default Read10things;