import React, { useContext } from 'react';
import firebase from '../../api/firebase';
import useForm from '../../hooks/useForm';
import Button from '@material-ui/core/Button';
import { IoIosSend } from 'react-icons/io'
import CheckBox from '../shared/CheckBox'
import './SymptomsForm.scss'
import { makeStyles } from '@material-ui/core/styles';
import { db, actions } from '../../store'
import { GetLocationFromZipCode, GetZipCode } from '../../api/api';
import ThankYou from '../ThankYou'

const SymptomsForm = () => {
    const { state, dispatch } = useContext(db);

    const init = {
        dateentered: '',
        acknowledge: false,
        cough: false,
        coughDetail: '',
        fever: false,
        feverDetail: '',
        Duration: '',
        shortnessBreath: false,
        shortBreathDetail: '',
        Zipcode: state.Zipcode,
        lat: state.lat, lng:
            state.lng, countyName: ''
    };
    const [fields, setFields, reset] = useForm(init);

    const send = () => {

        GetLocationFromZipCode(fields.Zipcode, "us").then((result) => {
            GetZipCode(+parseFloat(result.data[0].lat).toFixed(4), +parseFloat(result.data[0].lon).toFixed(4)).then(r => {
                fields.countyName = r.data.address.county;
                fields.dateentered = Date.now();
                fields.lat = +parseFloat(result.data[0].lat).toFixed(4);
                fields.lng = +parseFloat(result.data[0].lon).toFixed(4);
                fields.cityName = state.cityName;
                fields.stateName = state.stateName;
                firebase.ref("patients").push(fields);
                dispatch({ type: actions.FormSubmitted })
                reset(init);
            })

        }).catch(r => console.log(r));
    }

    const useStyles = makeStyles({
        root: {
            backgroundColor: '#43425c',
            borderRadius: 3,
            boxShadow: '0 3px 5px -2px rgb(52, 52, 52)',
            color: 'white',
            height: 48,
            padding: '0 30px',
            width: '100%'
        },
    });
    const classes = useStyles();

    return (
        <>

            {(!state.FormSubmitted) && (<div data-thankyou="" className="SymptomsForm"><ThankYou /></div>)}
            {(state.FormSubmitted) && (
                <div className="SymptomsBox SymptomsForm ">
                    <div className="SymptomsFormHeader">Corona Virus</div>
                    <div className="SymptomsFormHeader">Tracking</div>
                    <div className="SymptomsFormIntructions">Please add your Zipcode and check off any symptoms that may apply to you</div>
                    <div className="SymptomsRowZipcode">
                        <div>
                            <input name="Zipcode"
                                value={fields.Zipcode}
                                onChange={setFields}
                                type="number" pattern="[0-9]*"
                                placeholder="ZipCode" />
                        </div>
                    </div>
                    <div className="SymptomsRow"><CheckBox className="CheckBoxLabel" label="Coughs" name="cough" isActive={fields.cough} callBack={setFields.bind(this)} />
                        {(fields.cough) && (<div><input name="coughDetail" value={fields.coughDetail} onChange={setFields} placeholder="Any details (optional)" /></div>)}</div>
                    <div className="SymptomsRowFever"><CheckBox className="CheckBoxLabel" label="Fever" name="fever" isActive={fields.fever} callBack={setFields.bind(this)} />
                        {(fields.fever) && (<div><input name="feverDetail" value={fields.feverDetail} onChange={setFields} placeholder="Any details (optional)" /></div>)}

                        {(fields.fever) && (<div><select className="Duration" name="Duration" value={fields.Duration} onChange={setFields}>
                            <option value="> 1 day" >> 1 day</option>
                            <option value="1 day">1 day</option>
                            <option value="2 days">2 days</option>
                            <option value="3 days">3 days</option>
                            <option value="4 days">4 days</option>
                            <option value="5 days">5 days</option>
                            <option value="6+ days">6+ days</option>
                        </select>
                        </div>)}  </div>
                    <div className="SymptomsRowBreath"><CheckBox className="CheckBoxLabel" label="Shortness of Breath" name="shortnessBreath" isActive={fields.shortnessBreath} callBack={setFields.bind(this)} />
                        {(fields.shortnessBreath) && (<div><input name="shortBreathDetail" value={fields.shortBreathDetail} onChange={setFields} placeholder="Any details (optional)" /></div>)}</div>

                    <CheckBox className="CheckBoxLabelAck"
                        label="I attest that these symptoms are true and accurate to the best of my knowledge"
                        name="acknowledge" isActive={fields.acknowledge} callBack={setFields.bind(this)} />
                    {
                        (fields.acknowledge && fields.Zipcode && (fields.cough || fields.fever || fields.shortnessBreath))
                            ?
                            (<Button onClick={send} variant="contained" color="primary" size="medium" className={classes.root} startIcon={<IoIosSend />}>Add to Map Report</Button>)
                            : (<Button disabled onClick={send} variant="contained" color="primary" size="medium" className={classes.root} startIcon={<IoIosSend />}>Add to Map Report</Button>)
                    }
                    <div className="SymptomsFormIntructions">Thank you for helping your community</div>
                </div>)}
        </>
    )
}
export default SymptomsForm